<template>
  <div class="login">
    <!-- <van-nav-bar title="登录" left-arrow @click-left="onClickLeft" /> -->
    <van-image class="logo" width="5rem" height="5rem" :src="require('@/assets/icons/logo.png')" alt="Logo" />
    <div v-if="type == 'vcodeLogin'" class="login-body vcode">
      <van-form @submit="onSubmit">
        <van-field
          label="用户名"
          v-model="phone"
          type="tel"
          maxlength="11"
          placeholder="手机号"
          :rules="[{ required: true, message: '请填写手机号' }]"
        />
        <van-field
          label="验证码"
          v-model="vcode"
          type="digit"
          maxlength="4"
          center
          clearable
          placeholder="短信验证码"
          :rules="[{ required: true, message: '请填写短信验证码' }]"
        >
          <template #button>
            <van-button size="small" type="primary" @click="getVcode">{{time}}</van-button>
          </template>
        </van-field>

        <div style="margin: 16px;">
          <div class="toPwdLogin" @click="toggle('passwordLogin')">密码登录</div>
          <van-button round block color="#1baeae" native-type="submit">登录</van-button>
        </div>
      </van-form>
    </div>
    <div v-if="type == 'passwordLogin'" class="login-body password">
      <van-form @submit="onSubmit">
        <van-field
          label="手机号"
          v-model="phone"
          type="tel"
          maxlength="11"
          placeholder="手机号"
          :rules="[{ required: true, message: '请填写手机号' }]"
        />
        <van-field
          label="密码"
          v-model="password"
          type="password"
          maxlength="16"
          clearable
          placeholder="密码"
          :rules="[{ required: true, message: '请填写密码' }]"
        >
        </van-field>

        <div style="margin: 16px;">
          <div class="toPwdLogin" @click="toggle('vcodeLogin')">短信登录</div>
          <van-button round block color="#1baeae" native-type="submit">登录</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
  import { reactive, toRefs } from 'vue';
  import { showToast  } from 'vant'
  import { useRouter } from 'vue-router'
  import { setLocal} from "@/utils/util"
  import { sendVerifyCode, login,getOpenId} from "@/service/user"

  export default {
    data(){
      return {
        appid:'' ,
        appsecret: '',
        openid: "",
        urlNow:""
      };
    },
    mounted:function(){
        let code = this.getUrlKey("code");
        this.appid=process.env.VUE_APP_WX_APP_ID;
        this.appsecret=process.env.VUE_APP_WX_APP_SECRET;
        this.urlNow=process.env.VUE_APP_WX_URL;
        if (code) {
          getOpenId(code)
        }else{
          this.getCodeApi();
        }
    },

    methods:{
      getCodeApi() {
        //获取code
        // let urlNow ='http://windguzi.vicp.cc/login';
        // let scope='snsapi_userinfo';    //snsapi_userinfo snsapi_base   //静默授权 用户无感知

        let url =
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
          this.appid +
          "&redirect_uri=" +
          this.urlNow +
          "&response_type=code&scope=snsapi_base#wechat_redirect";
        window.location.href = url;
      },

      getUrlKey: function (name) {
        //获取url 参数
        let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
        let r = window.location.search.substr(1).match(reg);
        if (r != null) return unescape(r[2]);
        return null;
      },
    },
    setup() {
      const router= useRouter();
      let isClick = true;
      const state = reactive({
        phone: '',
        vcode: '',
        username: '',
        password: '',
        type: 'vcodeLogin',
        time: '获取验证码',
        loginType:''
      });
      // 登录成功后跳转
      const redirect = router.currentRoute.value.query.redirect ? router.currentRoute.value.query.redirect : '/';
      // 后退
      const from = router.currentRoute.value.query.from ? router.currentRoute.value.query.from : '/';

      // 切换登录和注册两种模式
      const toggle = (v) => {
        state.type = v
        state.verify = ''
      };
      const onClickLeft = () => router.replace(from);
      // 获取验证码
      const getVcode = async () => {
        if(isClick) {
          if (/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(state.phone)) {
          await sendVerifyCode(state.phone);  // 获取验证码接口
            isClick = false;
            let s = 60;
            state.time = s + 's';
            let interval = setInterval(() => {
              s--
              state.time = s + 's'
              if (s < 0) {
                state.time = '重新获取'
                isClick = true
                clearInterval(interval)
              }
            }, 1000)
          } else {
            showToast ('请输入正确的手机号码');
          }
        }
      };
      const onSubmit = async () => {
        if(state.type=='vcodeLogin'){
           state.loginType=1;
        }else{
          state.loginType=2;
        }
        const res = await login({ mobile: state.phone, verfyCode: state.vcode,code:state.loginType,password:state.password});
        if(res.data.code<0){
          showToast (res.data.msg);
        }else{
          if(!res.data.token.openId){
            //没有获取openId,重新获取
            showToast ("网络异常，请重新登录");
            router.push({path:"/login"});
            setTimeout(function () {
              window.location.reload();
            }, 100);
          }else{
            setLocal('token-hyxt', res.data.token);
            router.replace(redirect);
          }
        }

      }

      return {
        ...toRefs(state),
        router,
        redirect,
        toggle,
        onClickLeft,
        getVcode,
        onSubmit,
      }
    }
  }
</script>

<style lang="scss" scoped>
  .login {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #fff;
    .logo {
      margin: 15% auto;
    }
    .login-body {
      margin: 0px auto;
      width: 90%;
      .toPwdLogin {
        font-size: 24px;
        color: red;
        padding: 24px 0;
      }
    }
  }
</style>
